import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { store } from './store';
// eslint-disable-next-line
import i18n from './i18n';
import 'react-native-get-random-values';
import { ToastProvider } from './context/ToastContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense>
    <Provider store={store}>
    <ToastProvider>

      <App />
      </ToastProvider>
    </Provider>
  </Suspense>
);

reportWebVitals();
