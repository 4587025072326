import React from 'react';
// **** Main CSS **** //
import './assets/scss/main.scss';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/home/Home';
import Contact from './pages/contact/Contact';
import AboutUs from './pages/about-us/AboutUs';
import MyAccount from './pages/my-account/MyAccount';
import RedirectVerifyEmail from './components/RedirectVerifyEmail';
import CookiePolicy from './pages/cookie-policy/CookiePolicy';
import PageNotFound from './pages/page-not-found/PageNotFound';
import Faq from './pages/faq/Faq';
import Maintenance from './pages/maintenance/Maintenance';
import CompatibleDevices from './pages/compatible-devices/CompatibleDevices';
import { useSelector } from 'react-redux';
import ScrollToTop from './components/layout/ScrollToTop';
import Footer from './components/layout/Footer';
import BundleDetails from './pages/bundle-details/BundleDetails';
import Paypal from './pages/web-paypal/Paypal';
import Pricing from './pages/pricing/Pricing';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import TermsAndConditions from './pages/terms-and-conditions/TermsAndConditions';
import PaymentSummary from './components/payment/PaymentSummary';
import CMS from './CMS';


function App() {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const siteMaintenance = useSelector(state => state.sitemaintenance.sitemaintenanceInfo);
  const isUnderMaintenance = (siteMaintenance === 'true') ? true : false;

  return (
    <>
      <Router>
      <CMS>
        <ScrollToTop />
        <Routes>
          <>
            {isUnderMaintenance
              ? <Route index element={<Maintenance />} />
              : <>
                <Route index exact path='/' element={< Home />} />
                <Route path='/about-us' element={< AboutUs />} />
                <Route path='/contact' element={< Contact />} />
                <Route path='/my-account' element={isLoggedIn ? < MyAccount /> : <Navigate to="/" />} />
                <Route path='/update-email' element={<RedirectVerifyEmail />} />
                <Route path='/verify-email' element={<RedirectVerifyEmail />} />
                <Route path='/reset-password' element={<RedirectVerifyEmail />} />
                <Route path='/cookie-policy' element={<CookiePolicy />} />
                <Route path='/faq' element={<Faq />} />
                <Route path='/bundles/:id' element={<BundleDetails />} />
                <Route path='/paypal' element={<Paypal />} />
                <Route path='/compatible-devices' element={<CompatibleDevices />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
                <Route path='/pricing' element={<Pricing />} />
                <Route path='/paymentresponse' element={<PaymentSummary />} />
                <Route path='*' element={<PageNotFound />} />
              </>
            }
          </>
        </Routes>
        {window.location.pathname !== '/paypal' && <Footer />}
        </CMS>
      </Router>
    </>
  );
}

export default App;