import { Link } from "react-router-dom";
import supportImage from "../assets/images/support.png"
import { useToast } from "../context/ToastContext";

const SupportMailsSection = () => {
  const { labels, general } = useToast();
  return (
    <section className="support-mails-section">
    <div className="custom-container">
        <div className="grid grid-nogutter contact-info-block">
            <div className="col-12 text-center">
                <img src={supportImage} alt="support_image" />
                <p>
                    {labels.LBL0044}
                </p>
                <span><Link to={`tel:${general.GEN0007}`}>{general.GEN0007}</Link> </span>
            </div>
        </div>
    </div>
    </section>
  );
};

export default SupportMailsSection;
