import React from "react";
import "../compatible-devices/CompatibleDevices.scss";
import * as PR from "../../prime-modules/index";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SubHeader from "../../components/SubHeader";
import SupportMailsSection from "../../components/SupportMailsSection";
import getAssets from '../../assets/data/brandingAssets.json';
import { useToast } from "../../context/ToastContext";

const CompatibleDevices = () => {
  const { labels, general } = useToast();

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{general.GEN0001} | {labels.LBL0113}</title>
        <title>{`${general.GEN0001} | ${labels.LBL0113}`}</title>
      </Helmet>

      {/* Header Section */}
      <SubHeader header={labels.LBL0113} subText={labels.LBL0114} />

      <section className="about-blocks-section compatible-devices-section">
        <div className="custom-container">
          <p className="text-center mb-6"><span className="font-medium">{labels.LBL0115}:</span> {labels.LBL0116} <span>{labels.LBL0117}</span> {labels.LBL0119}</p>
          <div className="faq-tabs device-tabs">
            <PR.TabView>
              <PR.TabPanel header={labels.LBL0118}>
                <div className="device-panel">
                  <div className="grid grid-nogutter">
                    <div className="col-12 lg:col-4 md:col-4">
                        <ul>
                          <li>iPhone 15 Pro Max</li>
                          <li>iPhone 15 Pro</li>
                          <li>iPhone 15 Plus</li>
                          <li>iPhone 15</li>
                          <li>iPhone 14 Pro Max</li>
                          <li>iPhone 14 Pro</li>
                          <li>iPhone 14 Plus</li>
                          <li>iPhone 14</li>
                          <li>iPhone 13 Pro Max</li>
                          <li>iPhone 13 Pro</li>
                          <li>iPhone 13 mini</li>
                          <li>iPhone 13</li>
                        </ul>
                    </div>
                    <div className="col-12 lg:col-4 md:col-4">
                        <ul>
                          <li>iPhone 12 Pro Max</li>
                          <li>iPhone 12 Pro</li>
                          <li>iPhone 12 mini</li>
                          <li>iPhone 12</li>
                          <li>iPhone 11 Pro Max</li>
                          <li>iPhone 11 Pro</li>
                          <li>iPhone 11</li>
                          <li>iPhone XS</li>
                          <li>iPhone XS Max</li>
                          <li>iPhone XR</li>
                          <li>iPhone SE 3 (2022)</li>
                          <li>iPhone SE 2 (2020)</li>
                        </ul>
                    </div>
                    <div className="col-12 lg:col-4 md:col-4">
                        <ul>
                          <li>iPad Pro 11″ (1st Gen or later)</li>
                          <li>iPad Pro 12.9″ (3rd Gen or later)</li>
                          <li>iPad Air (3rd Gen or later)</li>
                          <li>iPad (7th Gen or later)</li>
                          <li>iPad mini (5th Gen or later)</li>
                        </ul>
                    </div>
                  </div>
                  <p className="mt-3"><b>IMPORTANT</b></p>
                  <p>Check your device is <b>NOT LOCKED</b> to a single network.</p>
                  <p>Recommend iOS version: iOS 13.3 or later.</p>
                  <p>Note: iPhone and iPad models sold in mainland China are not equipped with an eSIM function.</p>
                  <div className="line"></div>
                  <PR.Image className="devices-img" src={getAssets.compatible_devices.images.apple} alt="Apple Devices"/>
                </div>
              </PR.TabPanel>
              <PR.TabPanel header={labels.LBL0120}>
                <div className="device-panel">
                  <div className="grid grid-nogutter">
                    <div className="col-12 lg:col-4 md:col-4">
                        <ul>
                          <li>Samsung Galaxy A54 5G</li>
                          <li>Samsung Galaxy Fold</li>
                          <li>Samsung Galaxy Note20</li>
                          <li>Samsung Galaxy Note20 5G</li>
                          <li>Samsung Galaxy Note20 Ultra</li>
                          <li>Samsung Galaxy Note20 Ultra 5G</li>
                          <li>Samsung Galaxy S24</li>
                          <li>Samsung Galaxy S24+</li>
                          <li>Samsung Galaxy S24 Ultra</li>
                          <li>Samsung Galaxy S23</li>
                          <li>Samsung Galaxy S23+</li>
                        </ul>
                    </div>
                    <div className="col-12 lg:col-4 md:col-4">
                        <ul>
                          <li>Samsung Galaxy S23 Ultra</li>
                          <li>Samsung Galaxy S23 FE</li>
                          <li>Samsung Galaxy S22</li>
                          <li>Samsung Galaxy S22+</li>
                          <li>Samsung Galaxy S22 Ultra</li>
                          <li>Samsung Galaxy S21 5G</li>
                          <li>Samsung Galaxy S21+ 5G</li>
                          <li>Samsung Galaxy S21 Ultra 5G</li>
                          <li>Samsung Galaxy S20</li>
                          <li>Samsung Galaxy S20+</li>
                          <li>Samsung Galaxy S20+ 5G</li>
                        </ul>
                    </div>
                    <div className="col-12 lg:col-4 md:col-4">
                        <ul>
                          <li>Samsung Galaxy S20 Ultra</li>
                          <li>Samsung Galaxy Z Flip</li>
                          <li>Samsung Galaxy Z Flip 3 5G</li>
                          <li>Samsung Galaxy Z Flip 3 5G Fold</li>
                          <li>Samsung Galaxy Z Flip 4</li>
                          <li>Samsung Galaxy Z Flip 5</li>
                          <li>Samsung Galaxy Z Flip 5G</li>
                          <li>Samsung Galaxy Z Fold 2</li>
                          <li>Samsung Galaxy Z Fold 3 5G</li>
                          <li>Samsung Galaxy Z Fold 4</li>
                          <li>Samsung Galaxy Z Fold 5</li>
                        </ul>
                    </div>
                  </div>
                  <p className="mt-3"><b>IMPORTANT</b></p>
                  <p>All Samsung <b>FE models</b> (except S23 FE) are <b>NOT</b> eSIM compatible</p>
                  <p>Check your device is <b>NOT LOCKED</b> to a single network.</p>
                  <div className="line"></div>
                  <PR.Image className="devices-img" src={getAssets.compatible_devices.images.samsung} alt="Samsung Devices"/>
                </div>
              </PR.TabPanel>
              <PR.TabPanel header={labels.LBL0121}>
                  <div className="device-panel">
                    <div className="grid grid-nogutter">
                      <div className="col-12 lg:col-4 md:col-4">
                          <ul>
                            <li>Google Pixel 2</li>
                            <li>Google Pixel 2 XL</li>
                            <li>Google Pixel 3</li>
                            <li>Google Pixel 3 XL</li>
                            <li>Google Pixel 3a</li>
                            <li>Google Pixel 3a XL</li>
                            <li>Google Pixel 4</li>
                            <li>Google Pixel 4a</li>
                            <li>Google Pixel 4a 5G</li>
                            <li>Google Pixel 4 XL</li>
                          </ul>
                      </div>
                      <div className="col-12 lg:col-4 md:col-4">
                          <ul>
                            <li>Google Pixel 5</li>
                            <li>Google Pixel 5a</li>
                            <li>Google Pixel 5a 5G</li>
                            <li>Google Pixel 6</li>
                            <li>Google pixel 6a</li>
                            <li>Google Pixel 6 Pro</li>
                          </ul>
                      </div>
                      <div className="col-12 lg:col-4 md:col-4">
                          <ul>
                            <li>Google Pixel 7a</li>
                            <li>Google Pixel 7</li>
                            <li>Google Pixel 7 Pro</li>
                            <li>Google Pixel 8</li>
                            <li>Google Pixel 8 Pro</li>
                            <li>Google Pixel Fold</li>
                          </ul>
                      </div>
                    </div>
                    <p><b>IMPORTANT</b></p>
                    <p>Check your device is <b>NOT LOCKED</b> to a single network.</p>
                    <div className="line"></div>
                    <PR.Image className="devices-img" src={getAssets.compatible_devices.images.google} alt="Google Devices"/>
                  </div>
              </PR.TabPanel>
              <PR.TabPanel header={labels.LBL0122}>
                <div className="device-panel">
                  <div className="grid grid-nogutter">
                    <div className="col-12 lg:col-4 md:col-4">
                        <h2>Huawei Devices</h2>
                        <ul>
                            <li>Huawei P40</li>
                            <li>Huawei P40 5G</li>
                            <li>Huawei P40 Pro</li>
                            <li>Huawei Mate 40 Pro</li>
                        </ul>
                    </div>
                    <div className="col-12 lg:col-4 md:col-4">
                        <h2>Oppo Devices</h2>
                        <ul>
                          <li>Oppo Find N2 Flip</li>
                          <li>Oppo Find X3 Pro</li>
                          <li>Oppo Find X5</li>
                          <li>Oppo Find X5 Pro</li>
                          <li>Oppo Reno 5 A</li>
                          <li>Oppo A55s 5G</li>
                        </ul>
                    </div>
                  </div>
                  <p className="mt-3"><b>IMPORTANT</b></p>
                  <p>Check your device is <b>NOT LOCKED</b> to a single network.</p>
                  <div className="line"></div>
                  <PR.Image className="devices-img" src={getAssets.compatible_devices.images.huawei} alt="Huawei & Oppo Devices"/>
                </div>
              </PR.TabPanel>
              <PR.TabPanel header={labels.LBL0123} className="last-tab">
                <div className="device-panel">
                  <h2>{labels.LBL0124}</h2>
                  <div className="grid grid-nogutter mb-3">
                  <div className="col-12 lg:col-4 md:col-4">
                      <ul>
                        <li>Microsoft Surface Pro X</li>
                        <li>Microsoft Surface Go 2</li>
                        <li>Microsoft Surface Pro LTE Advanced</li>
                        <li>Acer Swift 3</li>
                        <li>Acer Swift 7</li>
                        <li>Asus Mini Transformer</li>
                      </ul>
                  </div>
                  <div className="col-12 lg:col-4 md:col-4">
                      <ul>
                        <li>Asus NovaGo</li>
                        <li>Asus VivoBook Flip 14</li>
                        <li>HP Elitebook G5</li>
                        <li>HP Probook G5</li>
                        <li>HP Zbook G5</li>
                      </ul>
                    </div>
                    <div className="col-12 lg:col-4 md:col-4">
                      <ul>
                        <li>HP Specter Folio 13</li>
                        <li>Lenovo Yoga C630</li>
                        <li>Lenovo Miix 630</li>
                        <li>Lenovo Yoga 520</li>
                        <li>Samsung Galaxy Book 2</li>
                      </ul>
                    </div>
                  </div>
                  <h2>{labels.otherDevices}</h2>
                  <div className="grid grid-nogutter">
                  <div className="col-12 lg:col-4 md:col-4">
                      <ul>
                        <li>Fairphone 4</li>
                        <li>Gemini PDA</li>
                        <li>HONOR 90</li>
                        <li>HONOR Magic 4 Pro</li>
                        <li>Honor Magic 5 Pro</li>
                        <li>Motorola G53 5G</li>
                        <li>Motorola G54 5G</li>
                        <li>Motorola Razr</li>
                        <li>Motorola Razr 5G</li>
                        <li>Motorola RAZR 2019</li>
                        <li>Motorola RAZR 40</li>
                        <li>Motorola RAZR 40 Ultra</li>
                        <li>Motorola Edge 40</li>
                        <li>Motorola Edge 40 Pro</li>
                        <li>Motorola Edge 40 Neo</li>
                        <li>Motorola Edge 2022</li>
                      </ul>
                  </div>
                  <div className="col-12 lg:col-4 md:col-4">
                      <ul>
                        <li>Motorola Edge 2023</li>
                        <li>Motorola Edge+ 2023</li>
                        <li>Nokia G60 5G</li>
                        <li>Nokia X30 5G</li>
                        <li>Nokia XR21</li>
                        <li>Nuu X5</li>
                        <li>OnePlus 11</li>
                        <li>OnePlus 12</li>
                        <li>OnePlus Open</li>
                        <li>Oukitel WP30 Pro</li>
                        <li>Rakuten Mini</li>
                        <li>Rakuten Big S</li>
                        <li>Rakuten Big</li>
                        <li>Sharp AQUOS wish</li>
                        <li>Sharp AQUOS zero 6</li>
                        <li>Sony Xperia 10 III Lite</li>
                      </ul>
                    </div>
                    <div className="col-12 lg:col-4 md:col-4">
                      <ul>
                        <li>Sony Xperia 1 IV</li>
                        <li>Sony Xperia 5 IV</li>
                        <li>Sony Xperia 5 V</li>
                        <li>Sony Xperia 10 IV</li>
                        <li>Sony Xperia 10 V</li>
                        <li>Sony Xperia 1 V</li>
                        <li>Surface Duo</li>
                        <li>vivo V29</li>
                        <li>Xiaomi 12T Pro</li>
                        <li>Xiaomi 13</li>
                        <li>Xiaomi 13 Lite</li>
                        <li>Xiaomi 13 Pro</li>
                        <li>Xiaomi 13T</li>
                        <li>Xiaomi 13T Pro</li>
                        <li>Xiaomi 14</li>
                        <li>Xiaomi 14 Pro</li>
                      </ul>
                    </div>
                  </div>
                  <p className="mt-3"><b>IMPORTANT</b></p>
                  <p>Check your device is <b>NOT LOCKED</b> to a single network.</p>
                  <div className="line"></div>
                  <PR.Image className="devices-img" src={getAssets.compatible_devices.images.other} alt="Other Devices"/>
                </div>
              </PR.TabPanel>
            </PR.TabView>
          </div>
        </div>
      </section>

      <section className="inner-header-section aboutus-header-section support-section">
        <SupportMailsSection/>
      </section>
    </HelmetProvider>
  );
};

export default CompatibleDevices;