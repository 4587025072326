import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    AddressElement,
    Elements,
} from '@stripe/react-stripe-js';
import * as PR from "../../prime-modules/index";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { envConfig, globalConfig } from "../../constants";
import { trimFormData, renderPrice } from "../../utils/reuse";
import { cartFilterActions } from "../../store/cart";
import { stripePayment } from "../../services/api";
import { addressElementAppearance, cardElementstyles, cardNumberstyles } from "./utils";
import { v4 as uuidv4 } from 'uuid';
import { loadStripe } from "@stripe/stripe-js";
import parsePhoneNumber from 'libphonenumber-js'
import { useToast } from "../../context/ToastContext";

const stripePromise = await loadStripe(envConfig.REACT_APP_STRIPE_PUBLIC_KEY);

const StripePayment = ( props ) => {
    const dispatch = useDispatch();
    const { showToast, labels, validations} = useToast();
    const getIPCountry = useSelector((state) => state.country.IPCountry);
    const isAuth = useSelector(state => state.auth.isLoggedIn);
    const cartData = useSelector((state) => state.cart.catalogCart);
    const iccid = useSelector(state => state.esim.esimIccid);
    const userData = useSelector(state => state.auth.sessionData);
    const randomId = useSelector((state) => state.cart.randomId);
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
    const headers = useMemo(() => {
        return { sessionid: userData.sessionId, afid: affiliateTrackingData };
    }, [userData.sessionId, affiliateTrackingData]);
    const stripe = useStripe();
    const elements = useElements();
    const [stripePayError, setStripePayError] = useState('');
    const [payDisabled, setPayDisabled] = useState(false);
    const [display3DPopup, setDisplay3DPopup] = useState(false);
    const [auth3dSecureLink, setAuth3dSecureLink] = useState('');    
    const [payLabel, setPayLabel] = useState('');
    const totalPayAmount = ('promoCode' in cartData) ? cartData.discountedPrice : cartData.price;
    const [billingDetails, setBillingDetails] = useState("");
    const [error, setError] = useState('')
    useEffect(() => {
        const paymentLabel = labels.LBL0103 + " " + renderPrice(totalPayAmount)
        setPayLabel(paymentLabel);
    }, [totalPayAmount, stripe, elements, labels.LBL0103])

    const createStripePayment = (paymentMethodID, formData) => {
        const getPaymentData = (response) => {
            setPayDisabled(false);
            if (response.result === "SUCCESS") {
                paymentResponse(response.data);
            } else {
                props.paymentProps("", false)
                const error = response.error;
                showToast(error.severity, error.errorMsg);
            }
        }

        const splitPhone = num => {
            const phoneNumber = parsePhoneNumber(num)
            const callingCode = '+' + phoneNumber.countryCallingCode;
            const localPhoneNumber = phoneNumber.nationalNumber;
            return {callingCode, localPhoneNumber}
        }

        if (paymentMethodID && paymentMethodID !== '') {
            const customerDetails = {
                firstName: billingDetails?.name,
                lastName: '',
                email: formData.email,
                phone: splitPhone(billingDetails?.phone)
            }

            const getCartObj = { ...cartData };
            delete getCartObj.discountedPercentage;
            delete getCartObj.discountedPrice;
            delete getCartObj.dataAmountForDisplay;
            delete getCartObj.promoCodePrice;

            const dataPlans = [getCartObj];
            const deviceInfo = { device: globalConfig.deviceInfo };
            const paymentData = {
                contactInfo: customerDetails,
                orderSummary: {
                    dataPlans: dataPlans,
                    totalOrderAmount: cartData.price
                },
                paymentGateway: "Stripe",
                paymentMethodId: paymentMethodID,
                iccid: iccid,
                isEsimCompatible: globalConfig.isEsimCompatible,
                deviceInfo: deviceInfo,
                randomId: randomId ? randomId : uuidv4()
            }
            stripePayment(paymentData, headers, dispatch, getPaymentData)
        }
    };

    const paymentResponse = (payRes) => {
        if (payRes.status === "succeeded") {
            dispatch(cartFilterActions.removeRandomIdInPayment());
            on3DSecurePopupHide();
            props.paymentProps(payRes, false);
        } else if (payRes.status === "requires_action") {
            props.paymentProps("", true)
            setPayDisabled(true);
            setAuth3dSecureLink(payRes.authURL);
            setDisplay3DPopup(true);
        } else {
            props.paymentProps("", false)
            on3DSecurePopupHide();
            showToast('error', payRes.error);

        }
    }

    const formInitialValues = {
        email: isAuth ? userData.email : "",
    };

    const validationSchema = () => {
        return Yup.object().shape({
            email: Yup.string().trim()
                .email(labels.VLD0006)
                .required(labels.VLD0005),
        });
    }

    const handleSubmit = formData => {
        const trimmedFormData = trimFormData(formData);
        setStripePayError('');
        setPayDisabled(true);
        if (!stripe || !elements) {
            return;
        }
        // card number element as the card element
        const cardNumberElement = elements.getElement(CardNumberElement);
        if (cardNumberElement) {
            if(!billingDetails.name || !billingDetails?.phone){
                setError(validations.VLD0017);
                setPayDisabled(false)
            } else {
                billingDetails.email = formik.values.email;
                stripe.createPaymentMethod(
                    {
                        type: 'card',
                        card: cardNumberElement,
                        billing_details: billingDetails
                    })
                    .then(function (result) {
                        if (result.error) {
                            setPayDisabled(false);
                            setStripePayError(result.error.message);
                        } else {
                            props.paymentProps("", true)
                            setPayDisabled(true);
                            const payMethodID = result.paymentMethod.id;
                            if (payMethodID) {
                                createStripePayment(payMethodID, trimmedFormData);
                            } else {
                                props.paymentProps("", false)
                                setPayDisabled(false);
                                showToast('error', 'PMTMSG-15');
                            }
                        }
                    });
            }
        }
    };

    const formik = useFormik({
        initialValues: formInitialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true
    });

    const onCardChange = (e) => {
        (e.error) ? setStripePayError(e.error.message) : setStripePayError('');
    }

    const on3DSecurePopupHide = () => {
        setDisplay3DPopup(false)
        setAuth3dSecureLink('');
        props.paymentProps("", false)
        setPayDisabled(false);
    }

    const handleIframe = () => {
        const recieveIframeMsg = (event) => {
            if (event.origin === envConfig.REACT_APP_BASE_URL) {
                paymentResponse(event.data)
            }
        }
        window.addEventListener('message', recieveIframeMsg, false);
    }

      const addressChangeHandler = (event) => {
        setError("")
        if (event.complete) {
            setBillingDetails(event.value);
        } else {
            setBillingDetails({})
        }
    }

    return (
        <>
            <div className="payment-form">
                <h3>{labels.LBL0059}</h3>
                <div className="grid">
                   <div className="col-12">
                        <CardNumberElement className="card-number" options={{ style: { base: cardNumberstyles } }} onChange={onCardChange} />
                   </div>
                   <div className="col-12 lg:col-6 md:col-6">
                        <CardExpiryElement className="card-month" options={{ style: { base: cardElementstyles } }} onChange={onCardChange} />
                   </div>
                   <div className="col-12 lg:col-6 md:col-6">
                        <CardCvcElement className="card-month" options={{ style: { base: cardElementstyles } }} onChange={onCardChange} />
                   </div>
                </div>

                {stripePayError ? <div className='error-message mb-4 mt-2'>{stripePayError}</div> : ''}
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                <h3>{labels.LBL0060}</h3>

                    <div className="grid">
                        <div className="col-12 lg:col-12 md:col-12">
                            <label className="emailLabel">{labels.LBL0010}</label>
                            <PR.InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} style={{ 'width': '100%', 'marginTop': '5px', color: "#30313d" }} autoComplete="off" disabled={isAuth} />
                            {formik.errors.email && formik.touched.email ? <div className='error-message mt-3'>{formik.errors.email}</div> : ''}
                        </div>
                    </div>
                    <Elements stripe={stripePromise} options={{appearance: addressElementAppearance, locale: localStorage.getItem('i18nextLng')}}>
                                <AddressElement
                                    onChange={addressChangeHandler}
                                    options={{
                                        mode: 'billing',
                                        autocomplete: {
                                            mode: "google_maps_api",
                                            apiKey: envConfig.REACT_APP_GOOGLE_MAP_API_KEY
                                        },
                                        fields: {
                                            'phone': 'always',
                                        },
                                        validation: {
                                            phone: {
                                                required: 'always'
                                            }
                                        },
                                        defaultValues: {
                                            name: userData?.firstName ? userData.firstName + (userData?.lastName ? ' ' + userData.lastName : '') : '',
                                            phone: userData?.phone?.callingCode && userData?.phone?.localPhoneNumber ? (userData?.phone?.callingCode + userData?.phone?.localPhoneNumber) : '',
                                            address: {
                                                country: getIPCountry
                                            }
                                        }
                                    }}
                                />
                            </Elements>
                            {error && <div className='error-message mt-4'>{error}</div> }
                    <PR.Button type="submit" label={payLabel} className="continue-button" disabled={payDisabled || !formik.isValid} style={{ 'width': '100%' }} />
                </form>
            </div>

            <PR.Dialog header="" visible={display3DPopup} breakpoints={{ '960px': '85vw' }} style={{ width: '35vw' }} onHide={() => on3DSecurePopupHide()} resizable={false}>
                <iframe title="3d Secure" className="payment-iframe" src={auth3dSecureLink} width="100%" id="secure3dRef" onLoad={handleIframe} />
            </PR.Dialog>

        </>
    );
};

export default StripePayment;