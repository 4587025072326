import { useCallback, useEffect, useMemo, useState } from 'react';
import * as PR from '../../prime-modules/index';
import { getNotifications, updateNotificationStatus } from '../../services/api';
import { useDispatch, useSelector } from 'react-redux';
import { globalConfig } from '../../constants';
import DOMPurify from 'dompurify';
import moment from 'moment';
import { useToast } from '../../context/ToastContext';

const Notifications = () => {
    const dispatch = useDispatch();
    const { showToast, account } = useToast();
    const userData = useSelector(state => state.auth.sessionData);
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
    const headers = useMemo(() => {
        return { sessionid: userData.sessionId, afid: affiliateTrackingData };
    }, [userData.sessionId, affiliateTrackingData]);
    const [notifications, setNotifications] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const limit = globalConfig.notificationCount;
    const [loading, setLoading] = useState(false);
    const [unReadMessageCount, setUnReadMessageCount] = useState(0);
    const [show, setShow] = useState(null);

    const getNotificationsList = useCallback(async () => {
        setLoading(true);
        const request = "offset=" + ((pageNumber - 1 ) * limit) + "&limit=" + limit

        const getResponse = (response) => {
            if (response.result === "SUCCESS") {
                setLoading(false);
                let res = response.data && response.data.length > 0 ? response.data : [];
                let sortedResponse = res.sort((a, b) => (new Date(b.createdTs) - new Date(a.createdTs)))
                setNotifications(sortedResponse);
                let notifications = 0
                res.map((n) => !n.read && (notifications += 1));
                setUnReadMessageCount(notifications);
            } else if (response.result === "FAILED") {
                setLoading(false);
                const error = response.error;
                showToast(error.severity, error.errorMsg);
            } else {
                setLoading(false);
            }
        }

        getNotifications(request, headers, dispatch, getResponse)
    }, [dispatch, headers, limit, pageNumber, setUnReadMessageCount, showToast])

    const updateNotificationStatusAPI = useCallback(async (index, notification) => {
        setShow(show === index ? null : index);
        const request = {
            notificationId: notification.id,
            read: true
        }

        const getResponse = (response) => {
            if (response.result === "SUCCESS") {
                let notificationStatusChange = notifications.map(n => {
                    if(n.id === notification.id){
                        n.read = true
                        setUnReadMessageCount(prev => prev > 0 && (prev - 1))
                    }
                    return n
                });
                setNotifications(notificationStatusChange)
            } else if (response.result === "FAILED") {
                const error = response.error;
                showToast(error.severity, error.errorMsg);
            } else {
                setLoading(false);
            }
        }

        !notification.read && updateNotificationStatus(request, headers, dispatch, getResponse)
    }, [dispatch, headers, notifications, show, showToast])

    useEffect(() => {
        getNotificationsList()
    }, [getNotificationsList])


    return (
        <>
            {!loading && notifications.length > 0 && <h2>{account.ACT0048}({unReadMessageCount})</h2>}
            {loading ? 
                <div className='loading'>
                    <div><i className='pi pi-spin pi-spinner'></i></div>
                </div>:
                notifications.length > 0
                    ? <>
                        {
                            notifications.map((notification, index) => {
                                return (
                                    <div className="notifications-list" key={notification.id}>
                                        <p>{!notification.read ? <b className='flex align-items-center'><span className='green-dot mr-2'></span> {notification.title}</b> : notification.title} </p>
                                        <p><i className="pi pi-calendar"></i> <span>{moment(notification.date).format("MMM Do YYYY, hh:mm a")}</span></p>
                                        {show === index && 
                                        <div className='flex bg-contain' key={index}>
                                            <p className="m-0" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(notification.message) }}></p>
                                            {notification.image && <div className='flex justify-content-center align-items-center qr-code-image'>
                                                <img src={"data:image/png;base64," + notification.image} alt="QRcode" />
                                            </div>}
                                        </div>} 
                                        <PR.Button label={show === index ? <span>{account.ACT0050}</span> : <span>{account.ACT0051}</span> } className="read-button p-button-rounded" onClick={() => updateNotificationStatusAPI(index, notification)} />
                                    </div>
                                )
                            })
                        }
                        <ul className="pagination flex align-items-center">
                            <li className={pageNumber === 1 ? "disabled" : ""} onClick={() => setPageNumber(prev => prev - 1)} >{account.ACT0052}</li>
                            <li className="page-no"><span>{pageNumber}</span></li>
                            <li className={notifications.length < limit ? "disabled" : ""} onClick={() => setPageNumber(prev => prev + 1)}>{account.ACT0053}</li>
                        </ul>
                    </>
                    : <h3>{account.ACT0049}</h3>
            }
        </>
    );
}

export default Notifications;