import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import { visa, masterCard, appleIcon, gpayIcon, appStore, googlePlayStore } from "../../assets/images";
import { globalConfig } from '../../constants';
import getAssets from "../../assets/data/brandingAssets.json";
import { useToast } from '../../context/ToastContext';

const Footer = () => {
  const { labels, general, isBase64Image } = useToast();
  const [year] = useState(new Date().getFullYear())
  const [showAppLinks, setShowAppLinks] = useState(true);

  const appLinksHandler = () => {
    setShowAppLinks(false);
  }

  const footerRef = useRef(null);
  const [isFooterVisible, setIsFooterVisible] = useState(false);

  useEffect(() => {
    const footerObserver = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        setIsFooterVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1,
      }
    );
    const footerRefCurrent = footerRef.current
    if (footerRefCurrent) {
      footerObserver.observe(footerRefCurrent);
    }
    return () => {
      if (footerRefCurrent) {
        footerObserver.unobserve(footerRefCurrent);
      }
    };
  }, []);

  return (
    <>
      {/* Sticky window section */}
    {(window.location.pathname === "/" && showAppLinks) &&
    (<div className={isFooterVisible ? 'sticky-window footer-visible' : 'sticky-window'}> 
        <p>{labels.LBL0056} <span>eSIMCrew</span> {labels.LBL0062}</p>
        <Link to="https://apps.apple.com/us6rem/app/esimcrew/id6504028276" target='_blank' rel='noreferrer'>
          <PR.Image src={appStore} alt="App Store" />
        </Link>
        <Link to="https://play.google.com/store/apps/details?id=com.bluehouse.eSIMCrew&pli=1" target='_blank' rel='noreferrer'>
          <PR.Image src={googlePlayStore} alt="Google Play Store" />
        </Link>
        <i className="pi pi-times sticky-close" onClick={() => appLinksHandler()}></i>
      </div>)
}
      {/* Sticky window section */}

      <footer ref={footerRef}>
        <div className='footer-info grid grid-nogutter text-center'>
          <div className='col-12 lg:col-12 md:col-12 footer-logo'>
            <Link to="/">
              <PR.Image className="logo" src={isBase64Image(general.GEN0002) ? `data:image/png;base64,${general.GEN0002}` : getAssets.logo.logo_dark} alt="logo" width="200"/>
            </Link>
            <br />
            <span className='social-span'>{labels.LBL0063}</span>
            <ul>
              <li><Link to={getAssets.footer.socailMedia.facebook} target='_blank' rel='noreferrer'className='social-image facebook'></Link></li>
              <li><Link to={getAssets.footer.socailMedia.instagram} target='_blank' rel='noreferrer'className='social-image instagram'></Link></li>
              <li><Link to={getAssets.footer.socailMedia.linkedin} target='_blank' rel='noreferrer'className='social-image linkedin'></Link></li>
            </ul>
          </div>
        </div>
        <div className='grid grid-nogutter align-items-center justify-content-between copyrights-div'>
          <div className='left flex gap-3'>
            <p>&copy;{year} {labels.LBL0064} <Link to="https://bluehouse-technology.co.uk/" target="_blank" >Bluehouse Technology</Link> for <Link to="https://www.meggatelecom.com" target="_blank" > Megga Telecom</Link> - {labels.LBL0065}</p> |
              <Link to = "/terms-and-conditions" target='_blank'> Terms & Conditions</Link>
              <Link to = "/privacy-policy" target='_blank'> Privacy Policy</Link>
          </div>
          <div className='right'>
            <span>{globalConfig.domainName + " " + labels.LBL0086}</span>
            <ul className='flex align-items-center'>
              <li><PR.Image src={visa} alt="Visa" /></li>
              <li><PR.Image src={masterCard} alt="Master Card" /></li>
              <li><PR.Image src={appleIcon} alt="Apple Pay" /></li>
              <li><PR.Image src={gpayIcon} alt="Google Pay" /></li>
            </ul>
          </div>
        </div>
        <PR.ScrollTop className='scroll-to-top' icon="pi pi-arrow-up" />
      </footer>
    </>
  )
}
export default Footer