import Africa from "./Africa.jpg"
import Asia from "./Asia.jpg"
import Europe from "./Europe+.jpg"
import LatinAmerica from "./LatinAmerica.jpg"
import MiddleEast from "./MiddleEast.jpg"
import NorthAmerica from "./NorthAmerica.jpg"
import Oceania from "./Oceania.jpg"
import Global from "./Global.jpg"
import Hawaii from "./Hawaii.jpg"
import CanaryIslands from "./CanaryIslands.jpg";
import Bonaire from "./Bonaire.png";
import Curacao from "./Curacao.png";
import NetherlandsAntilles from "./Netherlands-Antilles.png";
import SintMaarten from "./Sint-Maarten.png";
import SouthSudan from "./South-Sudan.png";
import SaintBarthelemy from "./SaintBarthelemy.jpg";
import SaintMartin from "./SaintMartin.jpg";

const countryImages = {
    "Africa": Africa,
    "Asia": Asia,
    "Europe+": Europe,
    "LATAM": LatinAmerica,
    "Middle East": MiddleEast,
    "North America": NorthAmerica,
    "Oceania": Oceania,
    "Global": Global,
    "US-HI": Hawaii,
    "IC": CanaryIslands,
    "BQ": Bonaire,
    "CW": Curacao,
    "AN": NetherlandsAntilles,
    "SX": SintMaarten,
    "SS": SouthSudan,
    "BL": SaintBarthelemy,
    "MF": SaintMartin,

}
export const renderRegionalCountryImage = iso => (
    countryImages[iso] ? countryImages[iso] : ''
)

