export const paymentSteps = [
    {
        label: 'Card details'
    },
    {
        label: 'Billing details'
    },
    {
        label: 'Payment in Process'
    },
    {
        label: 'Payment successful'
    }
];
export const cardNumberstyles = {
    iconColor: '#666EE8',
    color: '#495057',
    fontWeight: 400,
    fontSize: '15px',

    '::placeholder': {
        color: '#b2b0b0',
        fontSize: '14px',
        fontWeight: 400,
    },
}

export const cardElementstyles = {
    iconColor: '#666EE8',
    color: '#495057',
    lineHeight: '40px',
    fontWeight: 400,
    fontSize: '15px',

    '::placeholder': {
        color: '#b2b0b0',
        fontSize: '14px',
        fontWeight: 400,
    },
}

export const addressElementAppearance = {
    theme: 'stripe',
    'rules': {
        '.Input': {
            'backgroundColor': '#F7F7F7',
            'border': '1px solid rgba(226, 227, 238, 0.13)',
            'borderRadius': '9px',
            'padding': '15px 20px',
            'fontSize': '16px',

        },
        '.Input:focus': {
            'boxShadow': '0 0 0 0.2rem #C7D2FE'
        },
        '.Label': {
            'fontSize': '16px',
            'marginBottom': '10px',
        },
        '.Error': {
            'color': "red"
        },
    },
}