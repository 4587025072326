import React, { useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SubHeader from "../../components/SubHeader";
import BundlesList from "../../components/EsimBundles";
import { ScrollTo } from "../../utils/ScrollTo";
import './Pricing.scss'
import { useToast } from "../../context/ToastContext";

const Pricing = () => {
  const { labels, general } = useToast();
  const ref = useRef(null);

  useEffect(() => {
    ScrollTo(ref, -300);
  });

  useEffect(() => {
    localStorage.removeItem("PAYMENT-RESPONSE");
  }, []); 

  return (
     <HelmetProvider> 
      <Helmet>
          <meta charSet="utf-8" />
          <title>{`${general.GEN0001} | ${labels.LBL0066}`}</title>
      </Helmet>
      <div className="pricing-page">
        <SubHeader/>
      </div>
       {/* Plans Section*/}
      <section className="plan-section">
        <div className="grid align-items-center">
          <h2>{labels.LBL0155}</h2>
        </div>
        <div className="card">
          <div className="bundles-section" ref={ref}>
            <BundlesList />
          </div>
        </div>
      </section>

    </HelmetProvider>
  );
};

export default Pricing;