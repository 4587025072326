import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { messageCatalogActions } from "./store/messageCatalog";
import { getAssetsData } from "./services/api";
import getAssets from "./assets/data/brandingAssets.json"
import { useToast } from "./context/ToastContext";
import { loadGoogleFont } from "./utils/loadGoogleFont";

const CMS = ({ children }) => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();
    const { themeColors, general } = useToast();
    const trimColorCode = (colors) => colors ? colors.replace(/"/g, '').trim() : '';

    useEffect(() => {
        const getResponse = (response) => {
            if (response.result === "SUCCESS") {
                dispatch(messageCatalogActions.storeGeneral(response.data))
                let assert = response.data.general
                trimColorCode(assert['GEN0006.defaultPrimaryFont']) === "false" && trimColorCode(assert["GEN0006.fontBase"]) && loadGoogleFont(trimColorCode(assert["GEN0006.fontBase"]))
                trimColorCode(assert['GEN0006.defaultSecondaryFont']) === "false" && trimColorCode(assert["GEN0006.fontSecondary"]) && loadGoogleFont(trimColorCode(assert["GEN0006.fontSecondary"]))

            }
        }
        getAssetsData(i18n.language, 'general', getResponse)
    }, [i18n.language, dispatch])


    useEffect(() => {
        const getResponse = (response) => {
            if (response.result === "SUCCESS") {
                dispatch(messageCatalogActions.storeThemeColors(response.data))
            }
        }
        getAssetsData(i18n.language, 'themeColors', getResponse)
    }, [i18n.language, dispatch]);
    useEffect(() => {
        const getResponse = (response) => {
            if (response.result === "SUCCESS") {
                dispatch(messageCatalogActions.storeHeader(response.data))
            }
        }
        getAssetsData(i18n.language, 'header', getResponse)
    }, [i18n.language, dispatch])
    useEffect(() => {
        const getResponse = (response) => {
          if (response.result === "SUCCESS") {
            dispatch(messageCatalogActions.storeHome(response.data))
          } 
        }
        getAssetsData(i18n.language, 'home', getResponse)
      }, [i18n.language, dispatch])

    useEffect(() => {
        const getResponse = (response) => {
            if (response.result === "SUCCESS") {
                dispatch(messageCatalogActions.storeMessageCatalog(response.data))
            }
        }
        getAssetsData(i18n.language, 'messageCatalog', getResponse)
    }, [i18n.language, dispatch])

    useEffect(() => {
        const getResponse = (response) => {
            if (response.result === "SUCCESS") {
                dispatch(messageCatalogActions.storeLabels(response.data))
            }
        }
        getAssetsData(i18n.language, 'labels', getResponse)
    }, [i18n.language, dispatch])
    useEffect(() => {
        const getResponse = (response) => {
            if (response.result === "SUCCESS") {
                dispatch(messageCatalogActions.storeValidations(response.data))
            }
        }
        getAssetsData(i18n.language, 'validations', getResponse)
    }, [i18n.language, dispatch]);
    useEffect(() => {
        const getResponse = (response) => {
            if (response.result === "SUCCESS") {
                dispatch(messageCatalogActions.storeAboutUs(response.data))
            }
        }
        getAssetsData(i18n.language, 'aboutUs', getResponse)
    }, [i18n.language, dispatch])
    useEffect(() => {
        const getResponse = (response) => {
            if (response.result === "SUCCESS") {
                dispatch(messageCatalogActions.storeContact(response.data))
            }
        }
        getAssetsData(i18n.language, 'contact', getResponse)

    }, [dispatch, i18n.language])
    useEffect(() => {
        const getResponse = (response) => {
            if (response.result === "SUCCESS") {
                dispatch(messageCatalogActions.storeMyAccount(response.data))
            }
        }
        getAssetsData(i18n.language, 'myAccount', getResponse)
    }, [i18n.language, dispatch])

    const defaultPrimaryFont = trimColorCode(general?.['GEN0006.defaultPrimaryFont']);
    const primaryFont = trimColorCode(general?.['GEN0006.fontBase']);
    const fontPrimary = defaultPrimaryFont === "true" ? getAssets.fonts['font-base'] : primaryFont ?? getAssets.fonts['font-base'];
    const defaultSecondaryFont = trimColorCode(general?.['GEN0006.defaultSecondaryFont']);
    const secondaryFont = trimColorCode(general?.['GEN0006.fontSecondary']);
    const fontSecondary = defaultSecondaryFont === "true" ? getAssets.fonts['font-secondary'] : secondaryFont ?? getAssets.fonts['font-secondary'];
    document.documentElement.style.setProperty('--font-base', fontPrimary);
    document.documentElement.style.setProperty('--font-secondary', fontSecondary);
    document.documentElement.style.setProperty('--general_font_size', getAssets.fonts['general_font_size']);
    document.documentElement.style.setProperty('--white', trimColorCode('#ffffff'));
    document.documentElement.style.setProperty('--black', trimColorCode('#140e1c'));
    document.documentElement.style.setProperty('--error-msg', trimColorCode('#ff1900'));
    document.documentElement.style.setProperty('--primary', trimColorCode(themeColors.PRIMARY));
    document.documentElement.style.setProperty('--secondary', trimColorCode(themeColors.SECONDARY));
    document.documentElement.style.setProperty('--teritary', trimColorCode(themeColors.TERITARY));
    document.documentElement.style.setProperty('--quaternary', trimColorCode(themeColors.QUATERNARY));
    document.documentElement.style.setProperty('--quinary', trimColorCode(themeColors.QUINARY));
    document.documentElement.style.setProperty('--mainmenu', trimColorCode(themeColors.MAINMENU));
    document.documentElement.style.setProperty('--mainmenuover', trimColorCode(themeColors.MAINMENUOVER));
    document.documentElement.style.setProperty('--btnprimary', trimColorCode(themeColors.BTNPRIMARY));
    document.documentElement.style.setProperty('--btnprimaryover', trimColorCode(themeColors.BTNPRIMARYOVER));
    document.documentElement.style.setProperty('--btnsecondary', trimColorCode(themeColors.BTNSECONDARY));
    document.documentElement.style.setProperty('--btnsecondaryover', trimColorCode(themeColors.BTNSECONDARYOVER));
    document.documentElement.style.setProperty('--input-field', trimColorCode(themeColors.INPUTFIELD));
    document.documentElement.style.setProperty('--input-border', trimColorCode(themeColors.INPUTFIELDBORDER));
    document.documentElement.style.setProperty('--dropdown-bg', trimColorCode(themeColors.DROPDOWNBG));
    document.documentElement.style.setProperty('--dropdown-selected', trimColorCode(themeColors.DROPDOWNSELECTED));

    return <>{children}</>;
};
export default CMS;
