import React, { useState, useMemo } from 'react';
import * as PR from "../prime-modules/index";
import { Link } from "react-router-dom";
import { forgotPassword } from "../services/api";
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from "formik";
import { useToast } from '../context/ToastContext';

const ForgotPassword = (props) => {
    const dispatch = useDispatch();
    const { showToast, labels } = useToast();
    const [disableBtn, setDisableBtn] = useState(false);
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
    const headers = useMemo(() => {
        return { afid: affiliateTrackingData };
    }, [affiliateTrackingData]);

    const validationSchema = () => {
        return Yup.object().shape({
            email: Yup.string()
                .trim()
                .email(labels.VLD0006)
                .required(labels.VLD0005),
        });
    }
    const submitHandler = (formData) => {
        setDisableBtn(true);
        const getResponse = (response) => {
            if (response.result === "SUCCESS") {
                showToast("success", response.data); // forgotPwd.successMessage
                formik.resetForm();
            } else {
                const error = response.error;
                showToast(error.severity, error.errorMsg);
            }
            setDisableBtn(false);
        }
        const emailVal = formData.email.trim();
        forgotPassword(`forgotpassword?email=${emailVal}`, headers, dispatch, getResponse)
    }
    const formik = useFormik({
        initialValues: { email: "" },
        validationSchema: validationSchema,
        onSubmit: submitHandler
    });
    return (
        <>
            <div className="form-section forgot-password-section">
                <div className="form">
                    <h3>{labels.LBL0033}</h3>
                    <p>{labels.LBL0034}</p>
                    <form onSubmit={formik.handleSubmit} autoComplete="off">
                    <div className="input-field">
                        <PR.InputText id="email" name="email" placeholder={labels.LBL0037} className="w-12" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} autoComplete="off" />
                        {formik.errors.email && formik.touched.email ? <div className='error-message'>{formik.errors.email}</div> : ''}
                    </div>
                    <PR.Button label={labels.LBL0035} type='submit' disabled={disableBtn} className="login-btn signup-btn" />
                    <div className="text-center mt-5 px-6"><p>{labels.LBL0036} <Link onClick={() => props.renderFormState(true, 'signup')}>{labels.LBL0038}</Link></p></div>
                    </form>
                </div>   
            </div>
        </>
    );
};

export default ForgotPassword;