export const regionalGlobalCountries = [
  {
    name: "Africa",
    iso: "Africa",
  },
  {
    name: "Asia",
    iso: "Asia",
  },
  {
    name: "Europe+",
    iso: "Europe+",
  },
  {
    name: "Latin America",
    iso: "LATAM",
  },
  {
    name: "Middle East",
    iso: "Middle East",
  },
  {
    name: "North America",
    iso: "North America",
  },
  {
    name: "Oceania",
    iso: "Oceania",
  },
  {
    name: "Global",
    iso: "Global",
  },
]