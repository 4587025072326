import React from "react";
import { globalConfig } from "../../constants";
import "./TermsAndConditions.scss";

const TermsAndConditions = () => {
    return (
        <div className="p-4 terms-conditions">
            <p><strong>Terms &amp; Conditions</strong></p>
            <p><strong>GENERAL TERMS AND CONDITIONS</strong></p>
            <p><strong>1. VALIDITY OF GENERAL TERMS AND CONDITIONS</strong></p>
            <p>The following Terms and Conditions shall apply to all services
                rendered by Megga Telecom Ltd, hereafter referred to as Megga Telecom or
                the product name, 'eSIMCrew', in connection with the prepaid eSIM
                reselling.</p>
            <p>The following Terms and Conditions are provided on the website <a
                href={globalConfig.domainUrl} class="uri" target="_blank" rel="noreferrer">{globalConfig.domainName} </a> and the 'eSIMCrew' application.</p>
            <p>Megga Telecom may accept variant clauses only in the case of an
                explicit written agreement.</p>
            <p><strong>2. DESCRIPTION OF SERVICES</strong></p>
            <p><strong>2.1. eSIM RESELLING</strong></p>
            <p>Megga Telecom resells prepaid eSIMs.</p>
            <p>The Customer registers and buys its eSIMs on <a
                href={globalConfig.domainUrl}
                class="uri" target="_blank" rel="noreferrer">{globalConfig.domainName}</a> website and/or eSIMCrew App.
                Our payments are operated by Apple Pay, Google Pay, PayPal (<a
                    href="https://paypal.com/" target="_blank" rel="noreferrer"><u>https://paypal.com</u></a>) and Stripe (<a
                        href="https://stripe.com/" target="_blank" rel="noreferrer"><u>https://stripe.com</u></a>) with an alias
                of your credit card (virtual Credit Card Imprint).</p>
            <p><strong>2.2. REGISTRATION FOR USING eSIMCrew SERVICES</strong></p>
            <p>The Customer must accept the General Terms and Conditions to use
                eSIMCrew services. The customer will need to provide the following
                information: First Name, Last Name, Address (billing address), Email
                address on either the website  <a href={globalConfig.domainUrl}
                    class="uri" target="_blank" rel="noreferrer">{globalConfig.domainName}</a> or the eSIMCrew app.</p>
            <p><strong>2.3. eSIMCrew ENGAGEMENTS</strong></p>
            <p>eSIMCrew shall use reasonable endeavours to provide Customer quality
                service. However, eSIMCrew does not guarantee that the service will not
                be interrupted, provided on time, and be safe or fault-free.</p>
            <p><strong>2.4. CUSTOMER ENGAGEMENTS</strong></p>
            <p>In using the Equipment or Services provided by eSIMCrew, the Customer
                must not engage in any action that is abusive, illegal, or fraudulent or
                that causes the Network to be impaired or damaged.</p>
            <p>When the Customer breaches its obligations under Sec. 2.4, eSIMCrew
                may suspend the Customer's use of the Service. During any period of
                suspension, the Customer shall continue to pay all Charges due under
                this Agreement in respect of the suspended Services.</p>
            <p><strong>2.5. DEVICE COMPATIBILITY</strong></p>
            <p>The Customer is responsible for ensuring that their device is eSIM
                compatible and network-unlocked. Device compatibility may depend on the
                carrier and country of origin, the Customer must check the list of
                eSIM-compatible devices provided at the checkout.</p>
            <p>By checking the box that confirms that the Customer's device is eSIM
                compatible, the Customer is then held responsible for the accuracy of
                the information they provide.</p>
            <p>The eSIM compatibility list is not exhaustive, meaning newly
                announced eSIM-compatible devices may still need to be added.</p>
            <p><strong>3. START, DURATION, AND TERMINATION OF THE
                CONTRACT</strong></p>
            <p>The service contract between eSIMCrew and the Customer begins upon
                completing the order at the eSIMCrew website <a
                    href={globalConfig.domainUrl}
                    class="uri" target="_blank" rel="noreferrer">{globalConfig.domainName}</a> or via the eSIMCrew app.</p>
            <p>The Activation of the eSIM and acknowledgment of the Activation
                Policy is the Customer's responsibility.</p>
            <p>The contract will be terminated if the Customer does not have an
                active data package or has deleted the eSIM from the target device.</p>
            <p><strong>4. CHARGES AND PAYMENT</strong></p>
            <p><strong>4.1. PAYMENT CONDITIONS</strong></p>
            <p>The supported payment methods for eSIMCrew services are Credit/Debit
                Card, PayPal, Google Pay and Apple Pay.</p>
            <p>The currency of payment is in US Dollars($).</p>
            <p>The credit card transaction will be processed and secured by eSIMCrew
                providers PayPal (<a
                    href="https://paypal.com/" target="_blank" rel="noreferrer"><u>https://paypal.com</u></a>) and Stripe (<a
                        href="https://stripe.com/" target="_blank" rel="noreferrer"><u>https://stripe.com</u></a>).</p>
            <p><strong>4.2. CHARGES FOR USE</strong></p>
            <p>4.2.1. eSIMCrew states all Charges are inclusive of VAT unless
                otherwise specified.</p>
            <p>4.2.2. The Customer shall not be entitled to offset any of its claims
                against claims of eSIMCrew, except where the Customer's claims are
                undisputed or have been confirmed by final court judgment.</p>
            <p><strong>5. DELIVERY</strong></p>
            <p>The Customer will immediately see the purchased eSIM under the "My
                eSIMs" tab on the eSIMCrew website <a href={globalConfig.domainUrl}
                    class="uri">{globalConfig.domainName}</a> and/or the eSIMCrew app. The
                Customer will receive a confirmation email after the purchase.</p>
            <p><strong>6. FAIR USAGE POLICY</strong></p>
            <p>For all Unlimited Data Plans, eSIMCrew implement a fair usage policy; after 1GB has been used at full speed, the data speed will run at 512 kbps. The Data speed resets every 24 hours depending on when the Data Plan was first activated and the first 1GB will run at full speed after each reset.</p>
            <p><strong>7. REFUND / CANCELLATION / MODIFICATION POLICY</strong></p>
            <p>The Customer has the right to ask for a refund or eSIM-replacement if
                the eSIM cannot be <strong>installed and used</strong> due to a
                technical problem from eSIMCrew.</p>
            <p><strong>7.1. REFUNDS AND CANCELLATION</strong></p>
            <p><strong>7.1.1. POLICIES AND GUIDELINES</strong></p>
            <p>7.1.2. A refund request can be made within thirty (30) days from the
                date of purchase when the activation is no longer possible following
                extensive collaborative troubleshooting.</p>
            <p>7.1.3. Cooperation with the Customer to resolve the issue promptly is
                required for a refund to be granted</p>
            <p>7.1.4. Each data package has its own validity period. No refund of
                any form will be offered for the remaining data when the validity period
                expires.</p>
            <p><strong>7.1.5. Compensation:</strong> No refund or remuneration of
                any kind will be issued due to charges from alternate phones, alternate
                SIM cards, alternate providers, hotel phones, or other charges that are
                not directly linked to the Customer's eSIMCrew account. (See
                section <strong>8. LIABILITY AND WARRANTY</strong> in our Terms and
                Conditions)</p>
            <p><strong>7.1.6. Fraudulent purchases:</strong> eSIMCrew reserves the
                right to refuse any form of refund if there is evidence of abuse,
                violation of our Terms and Conditions, or any fraudulent activity
                connected with using eSIMCrew products and services.</p>
            <p><strong>7.1.7.Unauthorised purchases:</strong> The case will be
                subject to investigation and approval before processing any refund.
                eSIMCrew reserves the right to suspend any account associated with
                fraudulent activity.</p>
            <p><strong>7.1.8. Accidental purchases:</strong> Once Customers install
                the eSIM, it will be considered used. No refunds will be offered.</p>
            <p><strong>7.1.9. Incorrect charges:</strong> If the Customer reasonably
                and in good faith disputes an invoice or part of it, the Customer shall
                notify eSIMCrew of such a dispute within twelve (12) days of receipt of
                the invoice, providing details of why the invoiced amount is incorrect
                and, if possible, how much the Customer considers due. (See details in
                section <strong>4.2 CHARGES FOR USE</strong> under Terms and
                Conditions)</p>
            <p><strong>7.1.10. Other Reasons:</strong> If the refund request is not
                within the above, we will investigate the request on a case-by-case
                basis. If the refund is approved, a processing fee may apply. The
                maximum refund of credit a Customer can apply for must be equal to or
                less than the total amount they paid.</p>
            <p><strong>7.1.11. REFUND PROCESS</strong></p>
            <p>To request a refund, contact eSIMCrew’s support team via the Contact
                Us page or send a message to <a href={`mailto:${globalConfig.supportEmail}`}
                    class="email" target="_blank" rel="noreferrer">hello@esimcrew.com</a>. Please be aware that our refund
                policy above will apply.</p>
            <p>Depending on the nature of the issue, Customers will be asked for
                further information to support their refund request, such as screenshots
                of the device settings for technical issues or details of why the
                invoiced amount is incorrect and, if possible, how much the Customer
                considers is due, etc.</p>
            <p>Please refer to section 7.1.2 in this same document for refunds
                related to technical issues.</p>
            <p>Customers will have the option to credit back via their original
                payment method or as a replacement eSIM/Data Plan.</p>
            <p><strong>Refund to the original method:</strong> Once a refund is
                approved and issued, it can take up to thirty (30) business days to
                appear on a statement depending on the bank.</p>
            <p><strong>7.2. MODIFICATION</strong></p>
            <p>The eSIM data plans from eSIMCrew are offered as-is, and no further
                modifications or customization can be made based on individual requests
                once purchased.</p>
            <p><strong>8. LIABILITY AND WARRANTY</strong></p>
            <p>eSIMCrew is not responsible for detriments arising due to the
                proposed service not being constantly available. eSIMCrew provides no
                guarantee of constant availability of the network service.</p>
            <p>For any request, please contact  <a href={`mailto:${globalConfig.supportEmail}`}
                class="email" target="_blank" rel="noreferrer">hello@esimcrew.com</a>.</p>
        </div>
    );
}

export default TermsAndConditions;