import React, { useCallback, useEffect, useMemo, useState } from "react";
import "../bundle-details/BundleDetails.scss";
import * as PR from "../../prime-modules/index";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeaderMenu from "../../components/layout/HeaderMenu";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getBundleDetails, getCatalogsList } from "../../services/api";
import { renderPrice, setDataAmount, setNumberPrecision } from "../../utils/reuse";
import { countryActions } from "../../store/country";
import { cartFilterActions } from "../../store/cart";
import { scrollToBundlesActions } from "../../store/scrollToBundles";
import MakePayment from "../../components/payment/MakePayment";
import { useToast } from "../../context/ToastContext";

const BundleDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { labels, general } = useToast();
  const { id } = useParams();
  const [catalogData, setCatalogData] = useState({});
  let getCartData = useSelector((state) => state.cart.catalogCart);
  const [displayRoaming, setDisplayRoaming] = useState(false);
  const [loading, setLoading] = useState(true);
  const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
  const headers = useMemo(() => {
    return { afid: affiliateTrackingData };
  }, [affiliateTrackingData]);
  const [catalogs, setCatalogs] = useState([]);
  const [countryName, setcountryName] = useState('');
  const [checkoutFormState, setCheckoutFormState] = useState(false)

  const getCatalogs = useCallback((country) => {
    const getCatalogList = (response) => {
      if (response.result === "SUCCESS") {
        let catalogList = response.data && response.data.bundles ? response.data.bundles : [];
        if (catalogList.length > 0) {
          catalogList = catalogList.filter(c => c.name !== id)
          setCatalogs(catalogList);
        } else {
          setCatalogs([]);
        }
      }
      setLoading(false);
    }
    const queryParameters = 'countries=' + country + '&direction=asc&orderBy=price';
    getCatalogsList(queryParameters, headers, dispatch, getCatalogList)
  }, [dispatch, headers, id])

  const getBundleData = useCallback(() => {
    const getBundleList = (response) => {
      if (response.result === "SUCCESS") {
        if (response.data) {
          setCatalogData(response.data);
          const getCountry = response.data?.countries[0]['country'].iso;
          const getCountryName = response.data?.countries[0]['country'].name;
          setcountryName(getCountryName);
          getCatalogs(getCountry);
        } else {
          navigate('/')
        }
      } else {
        navigate('/');
      }
    };
    getBundleDetails(id, headers, dispatch, getBundleList)
  }, [dispatch, id, navigate, headers, getCatalogs]);

  useEffect(() => {
    getBundleData();
  }, [getBundleData]);

  const catalogPurchase = (catalogData) => {
    const catalogName = catalogData.name;
    const catalogPrice = setNumberPrecision(catalogData.price);

    const getCartObj = { ...getCartData };

    if (getCartObj !== catalogName) {
      const setCartData = {
        name: catalogName,
        description: catalogData.description,
        quantity: 1,
        price: +catalogPrice,
        speed: catalogData.speed,
        duration: catalogData.duration,
        dataAmount: catalogData.dataAmount,
        dataAmountForDisplay: catalogData.dataAmountForDisplay
      };
      dispatch(cartFilterActions.setCatalogCart(setCartData));
      dispatch(cartFilterActions.setCatalogCartAmount(+catalogPrice));
    }
    setCheckoutFormState(true);
  }

  const distributeColumns = (items, numColumns) => {
    const totalItems = items && items.length;
    const itemsPerColumn = Math.floor(totalItems / numColumns);
    let remainingItems = totalItems % numColumns;

    const columns = Array.from({ length: numColumns }, () => []);

    let itemIndex = 0;
    for (let columnIndex = 0; columnIndex < numColumns; columnIndex++) {
      const numItems = itemsPerColumn + (remainingItems > 0 ? 1 : 0);
      remainingItems -= 1;

      // Add the items to the current column
      columns[columnIndex] = items && items.slice(itemIndex, itemIndex + numItems);

      itemIndex += numItems;
    }
    return columns;
  }

  const setDataRoaming = (roaming) => {
    const countriesList = roaming && roaming.map(obj => obj.country.name);
    const columns = distributeColumns(countriesList, 4);
    return (roaming && ((roaming.length === 0)
      ? labels.LBL0104
      : <>{ labels.LBL0105} ({roaming.length + labels.LBL0106})
        <i className="roaming-tooltip pi pi-info-circle info-btn ml-2" title="View Roaming Countries" onClick={() => setDisplayRoaming(true)}></i>
        <PR.Dialog header={labels.LBL0107} className="roamingColumnsSection bundle-dialog" visible={displayRoaming} blockScroll={true} draggable={false} onHide={() => setDisplayRoaming(false)} style={{ width: '60vw' }} resizable={false}>
          <div className="grid roamingColumnsDialog">
            {columns && columns.map((column, columnIndex) => (
              <div key={columnIndex}>
                <ul key={columnIndex}>
                  {column.map((item, itemIndex) => (
                    <li key={itemIndex}>{item && item.split('(')[0].trim()}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </PR.Dialog>
      </>
    )
    )
  }

  const getNetworks = (countries) => {
    let networks = [];
    if (countries && countries.length > 0) {
      const networksList = countries[0]['networks'];
      if (networksList && networksList.length > 0) {
        networks = networksList;
      }
    }
    return networks;
  }

  const setNetworks = (countries) => {
    const networks = getNetworks(countries);
    return (networks &&
      ((networks.length === 0)
        ? <span>N/A</span>
        : networks.map((n, index) => (
          <li key={index} className="catalogNetworks">
            {n.name} {n.speeds && '(' + (setDataSpeed(n.speeds)) + ')'}
          </li>
        ))
      )
    )
  }

  const setDataDuration = (duration) => {
    return (duration && ((duration) + (duration > 1 ? labels.LBL0018 : labels.LBL0108)));
  };

  const setDataSpeed = (speed) => {
    return (speed && (speed.join('/')));
  };

  const setCatalogPrice = (price) => {
    return (price && (<span className="catalogPrice">{renderPrice(price)}</span>))
  };

  const viewBundles = (id) => {
    return navigate('/bundles/' + id);
  }

  const viewMoreCountries = () => {
    const catalogCountry = catalogData.countries[0]['country'];
    dispatch(countryActions.setCountry(catalogCountry));
    dispatch(cartFilterActions.deleteCatalogCart());
    dispatch(cartFilterActions.setCatalogCartAmount(0));
    dispatch(scrollToBundlesActions.setScrollToBundles(true));
    navigate('/');
  }

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${general.GEN0001} | ${labels?.LBL0147}`} </title>

      </Helmet>

      {/* Header Section */}
      <section className="inner-header-section">
        <div className="header">
          <header>
            <HeaderMenu />
          </header>
          <div className="fixed-height"></div>
        </div>
        <div className="grid header-text">
          <div className="col-12 text-center">
            <h1>{labels.LBL0016}</h1>
            <p>{labels.LBL0109}</p>
          </div>
        </div>
      </section>
      <section className="contact-form-section mb-5">
        <div className="grid">
          <div className="col-12">
            {
              loading ?
                <div className="form-box bundle-details-section">
                  <div className="grid loading-div">
                    <span className="pi pi-spin pi-spinner" style={{ fontSize: "2rem", margin: "auto" }}>
                    </span>
                  </div>
                </div>
                :
                <div className="form-box bundle-details-section">
                  <div className="grid">
                    <div className="col-12 md:col-8 lg:col-8">
                      <h2>{catalogData.description}</h2>
                    </div>
                    <div className="col-12 md:col-4 lg:col-4">
                      {catalogData.price && (
                        <h2 className="price">{renderPrice(catalogData.price)}</h2>
                      )}
                    </div>
                  </div>
                  <div className="bundle-info-box">
                    <div className="grid">
                      <div className="col-12 md:col-6 lg:col-3 line">
                        <p><span>{labels.LBL0024}</span>{setDataAmount(catalogData, labels)}</p>
                      </div>
                      <div className="col-12 md:col-6 lg:col-3 line">
                        <p><span>{labels.LBL0025}</span>{setDataDuration(catalogData.duration)}</p>
                      </div>
                      <div className="col-12 md:col-6 lg:col-3 line">
                        <p><span>{labels.LBL0026}</span>{setDataSpeed(catalogData.speed)}</p>
                      </div>
                      <div className="col-12 md:col-6 lg:col-3">
                        <p><span>{labels.LBL0027}</span>{setDataRoaming(catalogData.roamingEnabled)}</p>
                      </div>
                      <div className="col-12 md:col-12 lg:col-12 mt-2">
                        <p><span>{labels.LBL0028}</span></p>
                        <ul className="flex list-none">
                          {setNetworks(catalogData.countries)}
                        </ul>
                        <PR.Button className="buy-button p-button-rounded" type="submit" label={labels.LBL0032} onClick={() => { catalogPurchase(catalogData) }} />
                      </div>
                    </div>
                  </div>
                  <div className="bundle-list">
                    <h3>{labels.LBL0110} <span>{countryName}</span></h3>
                    <div className="grid">
                      <div className="col-12 md:col-12 lg:col-8">
                        <div className="grid">
                          {catalogs.map((rowData, index) => {
                            return (
                              <div className="col-12 md:col-6 lg:col-6" key={index}>
                                <div className="box">
                                  <ul className="flex align-items-center justify-content-between list-none">
                                    <li>{setDataAmount(rowData, labels)}</li>
                                    <li className="price">{setCatalogPrice(rowData.price)}</li>
                                  </ul>
                                  <ul className="flex align-items-center justify-content-between list-none">
                                    <li>
                                      <span>{setDataDuration(rowData.duration)}</span>
                                      <span>{setDataSpeed(rowData.speed)}</span>
                                    </li>
                                    <li><PR.Button className="view-button p-button-rounded" type="submit" label={labels.LBL0111} onClick={() => { viewBundles(rowData.name) }} /></li>
                                  </ul>
                                </div>
                              </div>)
                          })}
                        </div>
                      </div>
                      <div className="col-12 md:col-12 lg:col-4">
                        <div className="button-box">
                          <PR.Button className="view-more-button p-button-rounded" type="submit" label={labels.LBL0112} icon="pi pi-chevron-right" iconPos="right" onClick={() => { viewMoreCountries() }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            }
          </div>
        </div>

        <PR.Dialog className="forms-dialog payment-dialog" blockScroll={true} draggable={false} style={{ width: '75%' }} visible={checkoutFormState} onHide={() => setCheckoutFormState(false)} resizable={false}>
          <MakePayment setCheckoutFormState={setCheckoutFormState}/>
        </PR.Dialog>

      </section>
    </HelmetProvider>
  );
};

export default BundleDetails;