import React, { useCallback, useEffect, useMemo, useState } from "react";
import "../my-account/MyAccount.scss";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeaderMenu from "../../components/layout/HeaderMenu";
import * as PR from "../../prime-modules/index";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import Profile from "./Profile";
import MyEsims from "./MyEsims";
import EsimDetails from "./esim-details/EsimDetails";
import TopupEsims from "./topup-esims/TopupEsims";
import Notifications from "./Notifications";
import { useToast } from "../../context/ToastContext";
import { getUserEsimList } from "../../services/api";
import { esimIccidActions } from "../../store/esim";

const MyAccount = () => {
  const { account, general, showToast } = useToast()
  const userData = useSelector(state => state.auth.sessionData);
  const [activeIndex, setActiveIndex] = useState(0);
  const [displayScreenIndex, setDisplayScreenIndex] = useState(0);
  const [userId, setUserId] = useState("")
  const dispatch = useDispatch();
  const setDisplayScreen = (index) => {
    setDisplayScreenIndex(index);
  }
  const affiliateTrackingData = useSelector(
    (state) => state.affiliateTracking.affiliateTrackingInfo
  );
  const headers = useMemo(() => {
    return { sessionid: userData.sessionId, afid: affiliateTrackingData };
  }, [userData.sessionId, affiliateTrackingData]);

  const getEsims = useCallback(() => {
    dispatch(esimIccidActions.storeLaoding(true));
    const getEsimList = (response) => {
      if (response.result === "SUCCESS") {
        dispatch(esimIccidActions.storeLaoding(false));
        const sortedEsimValues = response.data?.sort(
          (a, b) => new Date(b.createdTs) - new Date(a.createdTs)
        ) || [];

        const alleSIMS = sortedEsimValues.map((esim) => {
          const activeBundles = esim?.activeBundlesData?.filter(
            (bundle) => bundle.bundleStatus === "active"
          ) || [];
          return { ...esim, activeBundles };
        });
        dispatch(esimIccidActions.storeAlleSIMS(alleSIMS))
      } else {
        dispatch(esimIccidActions.storeLaoding(false));
        const error = response.error;
        showToast(error.severity, error.errorMsg);
      }
    };
    getUserEsimList(headers, dispatch, getEsimList);
  }, [dispatch, headers, showToast]);
  useEffect(() => {
    getEsims();
  }, [getEsims]);

  return (
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${general.GEN0001} | ${account?.ACT0059}`} </title>
      </Helmet>
      {/* Header Section */}
      <section className="inner-header-section">
        <div className="header">
          <header>
            <HeaderMenu />
          </header>
          <div className="fixed-height"></div>
        </div>
        <div className="grid header-text">
          <div className="col-12 text-center">
            <h1>{account.ACT0060}</h1>
            <p>{account.ACT0061}</p>
          </div>
        </div>
      </section>

      <section className="contact-form-section">
        <div className="grid header-text">
          <div className="col-12">
            <div className="form-box">
              <h2>{account.ACT0059}</h2>
              <div className="card">
                <PR.TabView activeIndex={activeIndex} onTabChange={e => {
                    setActiveIndex(e.index);
                    setDisplayScreenIndex(0);
                  }}>

                  {/* Profile */}
                  <PR.TabPanel header={account.ACT0062} leftIcon="pi pi-user-edit mr-2">
                    <Profile />
                    {
                      // google or apple account users don't have access to change password
                      userData.signupType === '' &&
                      <div className="profile-section">
                        <ChangePassword />
                      </div>
                    }
                  </PR.TabPanel>
                  
                  {/* My eSIMs  */}
                  <PR.TabPanel header={account.ACT0063} leftIcon="pi pi-qrcode mr-2" className="myaccount-tab">
                    {/* Breadcrumb section */}
                    <div className="flex justify-content-between align-items-center mt-2">
                      <ul className="breadcrumbs-ul">
                        <li>{account.ACT0059}</li>
                        <li>/</li>
                        <li onClick={() => setDisplayScreenIndex(0)}> {(displayScreenIndex !== 0)? <Link>{account.ACT0067}</Link> : account.ACT0067}</li>
                        {displayScreenIndex === 1 && <>
                            <li>/</li>
                            <li>{account.ACT0064}</li>
                        </>}
                        {displayScreenIndex === 2 && <>
                            <li>/</li>
                            <li>{account.ACT0065}</li>
                        </>}
                      </ul>
                    </div>
                    {/* End breadcrumb section */}
                      {displayScreenIndex === 0 && <MyEsims activeIndex={activeIndex} setDisplayScreen={setDisplayScreen} setUserId={setUserId} />}
                      {displayScreenIndex === 1 && <EsimDetails setDisplayScreen={setDisplayScreen} userId={userId} />}
                      {displayScreenIndex === 2 && <TopupEsims setDisplayScreen={setDisplayScreen} />}
                  </PR.TabPanel>

                  {/* Notifications */}
                  <PR.TabPanel header={account.ACT0066} className="notifications-tab" leftIcon="pi pi-bell mr-2">
                      <Notifications />
                  </PR.TabPanel>

                </PR.TabView>
              </div>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};

export default MyAccount;