import React, { useEffect, useState } from 'react';
import * as PR from '../../prime-modules/index';
import { useTranslation } from 'react-i18next';

const SelectLanguageDropDown = () => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);
    const languages = [
        { label: 'English', value: 'en' },
        { label: 'Portuguese', value: 'pt' },
        { label: 'Spanish', value: 'es' },
        { label: 'Tagalog', value: 'tl' },
    ];

    const changeLanguage = (lang) => {
        setLanguage(lang.value);
        i18n.changeLanguage(lang.value);
    }
    useEffect(() => {
        const defaultLang = i18n.language.split('-')[0];
        i18n.changeLanguage(defaultLang);
        setLanguage(defaultLang);
    }, [i18n])

    return (
        <PR.Dropdown
            value={language}
            options={languages}
            optionLabel="label"
            optionValue="value"
            onChange={changeLanguage}
            panelClassName="language-dropdown"
            className='new-1'
        />
    );
}

export default SelectLanguageDropDown;